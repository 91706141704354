const collegeNames = [
	'STANFORD',
	'HARVARD',
	'MIT',
	'GEORGIA TECH',
	'COLUMBIA',
	'NYU',
	'UCLA',
	'THE IVY LEAGUE',
]
export default collegeNames
